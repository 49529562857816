import React, { useState, useEffect } from "react";

import Map from "@accrosoft-ltd/vf-careers-site-theme/src/components/Map";

export default function ContactPage() {
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    window.location = `mailto:info@staff86.com?body=${message}`;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-7">
          <h3>Contact us</h3>
          <p>You can leave a message or contact us on any of the following</p>
          <div className="d-flex align-items-center mb-3">
            <span className="fas fa-envelope mr-3"></span>
            <a href="mailto:info@staff86.com">info@staff86.com</a>
          </div>
          <div className="d-flex align-items-center mb-3">
            <span className="fas fa-phone mr-3"></span>
            <a href="tel:01244 6333 86">01244 6333 86</a>
          </div>
          <div className="d-flex align-items-center mb-3">
            <span className="fas fa-map-marker-alt mr-3"></span>
            <a href="https://goo.gl/maps/vw1dwMKCE1XL8kgP7" target="_blank">
              104 Watergate Street, Chester, CH1 2LF
            </a>
          </div>
        </div>
        <div className="card col-12 col-md-6 col-lg-5">
          <form className="p-4" onSubmit={onSubmit}>
            <div className="mb-3">
              <p>Leave us a message</p>
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message<span className="text-danger"> *</span>
              </label>
              <textarea
                className="form-control"
                type="text"
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <input
              className="btn btn--primary"
              type="submit"
              value="Send message"
            />
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col vf-map">
          <Map
            latitude={Number(53.1897)}
            longitude={Number(-2.89599)}
            zoom={12}
            scrollWheelZoom={true}
            tooltipText={"Staff 86"}
          />
        </div>
      </div>
    </>
  );
}
