import React, { useState, useEffect } from 'react';

import { withPrefix, Link } from 'gatsby';

import { Helmet } from 'react-helmet';

const images = {
  // image0: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Ascot+-+Bet+sign.jpeg',
  //   title: 'Bet with Ascot',
  // },
  // image1: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Ascot+-+Betting+Staff.jpeg',
  //   title: 'Ascot Betting Staff',
  // },
  // image2: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Ascot+-+sign.jpeg',
  //   title: 'Ascot',
  // },
  image3: {
    path: 'https://cdn1.accropress.com/staff86/gallery/Ascot+-+Staff+check+in.jpeg',
    title: 'Ascot Staff Check In',
  },
  // image4: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Emma+-+Ascot.jpeg',
  //   title: 'Emma at Ascot',
  // },
  // image5: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Haydock+Pic+1.jpeg',
  //   title: 'Haydock 1',
  // },
  // image6: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Haydock+pic+2.jpeg',
  //   title: 'Haydock 2',
  // },
  // image7: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/National+group.jpeg',
  //   title: 'National Group',
  // },
  // image8: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Office+pic.jpeg',
  //   title: 'Office',
  // },
  // image9: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Socials+1.2.jpg',
  //   title: 'Social pic',
  // },
  // image10: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Socials+1.jpeg',
  //   title: 'Social pic',
  // },
  // image11: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/Socials+2.jpeg',
  //   title: 'Social pic',
  // },
  // image12: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/St+Andrews+-+Josh+1.jpeg',
  //   title: 'St Andrews Josh',
  // },
  // image13: {
  //   path: 'https://cdn1.accropress.com/staff86/gallery/St+Andrews+-+Josh.jpeg',
  //   title: 'St Andrews Josh',
  // },

  //new images sent over

  image14: {
    path: 'img/gallery/Ascot-Bet-sign.jpeg',
    title: 'Ascot Bet Sign',
  },

  image15: {
    path: 'img/gallery/Ascot-Betting-Staff.jpeg',
    title: 'Ascot Bet Staff',
  },

  image16: {
    path: 'img/gallery/Ascot-Coach-uniform.jpeg',
    title: 'Ascot Coach Uniform',
  },

  image17: {
    path: 'img/gallery/Ascot-sign.jpeg',
    title: 'Ascot Sign',
  },

  image18: {
    path: 'img/gallery/Bolesworth-Christmas.jpeg',
    title: 'Bolesworth Christmas',
  },

  image19: {
    path: 'img/gallery/Bolesworth-Halloween-Pic-4.jpeg',
    title: 'Bolesworth Halloween',
  },

  image20: {
    path: 'img/gallery/BolesworthChristmas-Pic-7.jpeg',
    title: 'Bolesworth Christmas',
  },

  image21: {
    path: 'img/gallery/Chester-FC-4.jpeg',
    title: 'Chester FC 4',
  },

  image22: {
    path: 'img/gallery/ChesterFC-1.jpeg',
    title: 'Chester FC 1',
  },

  image23: {
    path: 'img/gallery/ChesterFC-2.jpeg',
    title: 'Chester FC 2',
  },

  image24: {
    path: 'img/gallery/Clockpic.jpeg',
    title: 'Clock',
  },

  image25: {
    path: 'img/gallery/DevaFest-Dodgy.jpeg',
    title: 'DevaFest Dodgy',
  },

  image26: {
    path: 'img/gallery/DevaFest-Sam.jpeg',
    title: 'DevaFest Sam',
  },

  image27: {
    path: 'img/gallery/DevaFest-Sign.jpeg',
    title: 'DevaFest Sign',
  },

  image28: {
    path: 'img/gallery/DevaFest-Stall.jpeg',
    title: 'DevaFest Stall',
  },

  image29: {
    path: 'img/gallery/Emma-Ascot.jpeg',
    title: 'Emma Ascot',
  },

  image30: {
    path: 'img/gallery/Freshers-Pic3.jpeg',
    title: 'Freshers',
  },

  image31: {
    path: 'img/gallery/Halloween-EmmaandPatricia.jpeg',
    title: 'Halloween Emma and Patricia',
  },

  image32: {
    path: 'img/gallery/HawardenFarmshop-Pic1.jpg',
    title: 'Hawarden Farmshop',
  },

  image33: {
    path: 'img/gallery/HawardenFarmshop-Pic2.jpeg',
    title: 'Hawarden Farmshop 2',
  },

  image34: {
    path: 'img/gallery/HaydockPic1.jpeg',
    title: 'Haydock',
  },

  image35: {
    path: 'img/gallery/Haydockpic2.jpeg',
    title: 'Haydock',
  },

  image36: {
    path: 'img/gallery/Nationalgroup.jpeg',
    title: 'National Group',
  },

  image37: {
    path: 'img/gallery/Officepic.jpeg',
    title: 'Office',
  },

  image38: {
    path: 'img/gallery/ReindeerLodge-4.jpeg',
    title: 'Reindeer Lodge',
  },

  image39: {
    path: 'img/gallery/ReindeerLodge-8.jpeg',
    title: 'Reindeer Lodge 8',
  },

  image40: {
    path: 'img/gallery/Rolex-pic.png',
    title: 'Rolex',
  },

  image41: {
    path: 'img/gallery/Socials-1-2.jpg',
    title: 'Socials',
  },

  image42: {
    path: 'img/gallery/Socials1.jpeg',
    title: 'Socials',
  },

  image43: {
    path: 'img/gallery/Socials2.jpeg',
    title: 'Socials',
  },

  image44: {
    path: 'img/gallery/Staff86&Bed.jpeg',
    title: 'Staff 86 & Bed',
  },

  image45: {
    path: 'img/gallery/StAndrews-Josh.jpeg',
    title: 'St Andrews Josh',
  },

  image46: {
    path: 'img/gallery/StAndrews-Josh1.jpeg',
    title: 'St Andrews Josh One',
  },

  image47: {
    path: 'img/gallery/StAndrews-Pic1.jpeg',
    title: 'St Andrews Josh Two',
  },
};

export default function GalleryPage() {
  useEffect(() => {
    // This runs the script
    if (typeof window !== 'undefined' && window.document) {
      // Your script here...
      window.baguetteBox.run('.tz-gallery');
    }
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Droid+Sans:400,700"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://rawgit.com/LeshikJanz/libraries/master/Bootstrap/baguetteBox.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/baguettebox.js/1.8.1/baguetteBox.min.js"></script>
      </Helmet>

      <div className="container gallery-container">
        <div class="tz-gallery">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image3.path}>
                <img src={images.image3.path} alt={images.image3.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image14.path}>
                <img src={images.image14.path} alt={images.image14.title} />
              </a>
            </div>
            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image15.path}>
                <img src={images.image15.path} alt={images.image15.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image16.path}>
                <img src={images.image16.path} alt={images.image16.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image17.path}>
                <img src={images.image17.path} alt={images.image17.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image18.path}>
                <img src={images.image18.path} alt={images.image18.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image19.path}>
                <img src={images.image19.path} alt={images.image19.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image20.path}>
                <img src={images.image20.path} alt={images.image20.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image21.path}>
                <img src={images.image21.path} alt={images.image21.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image22.path}>
                <img src={images.image22.path} alt={images.image22.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image23.path}>
                <img src={images.image23.path} alt={images.image23.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image24.path}>
                <img src={images.image24.path} alt={images.image24.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image25.path}>
                <img src={images.image25.path} alt={images.image25.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image26.path}>
                <img src={images.image26.path} alt={images.image26.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image27.path}>
                <img src={images.image27.path} alt={images.image27.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image28.path}>
                <img src={images.image28.path} alt={images.image28.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image29.path}>
                <img src={images.image29.path} alt={images.image29.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image30.path}>
                <img src={images.image30.path} alt={images.image30.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image31.path}>
                <img src={images.image31.path} alt={images.image31.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image32.path}>
                <img src={images.image32.path} alt={images.image32.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image33.path}>
                <img src={images.image33.path} alt={images.image33.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image34.path}>
                <img src={images.image34.path} alt={images.image34.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image35.path}>
                <img src={images.image35.path} alt={images.image35.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image36.path}>
                <img src={images.image36.path} alt={images.image36.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image37.path}>
                <img src={images.image37.path} alt={images.image37.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image38.path}>
                <img src={images.image38.path} alt={images.image38.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image39.path}>
                <img src={images.image39.path} alt={images.image39.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image40.path}>
                <img src={images.image40.path} alt={images.image40.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image41.path}>
                <img src={images.image41.path} alt={images.image41.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image42.path}>
                <img src={images.image42.path} alt={images.image42.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image43.path}>
                <img src={images.image43.path} alt={images.image43.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image44.path}>
                <img src={images.image44.path} alt={images.image44.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image45.path}>
                <img src={images.image45.path} alt={images.image45.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image46.path}>
                <img src={images.image46.path} alt={images.image46.title} />
              </a>
            </div>

            <div class="col-sm-12 col-md-4">
              <a class="lightbox" href={images.image47.path}>
                <img src={images.image47.path} alt={images.image47.title} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
